import { IconProps } from "./IconType";

export default function PluginsIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path
        d="M9.8 21H4V15.2C4.8 15.2 5.5 14.946 6.1 14.438C6.7 13.93 7 13.284 7 12.5C7 11.7167 6.7 11.071 6.1 10.563C5.5 10.055 4.8 9.80067 4 9.8V4H10C10 3.3 10.2417 2.70833 10.725 2.225C11.2083 1.74167 11.8 1.5 12.5 1.5C13.2 1.5 13.7917 1.74167 14.275 2.225C14.7583 2.70833 15 3.3 15 4H21V10C21.7 10 22.2917 10.2417 22.775 10.725C23.2583 11.2083 23.5 11.8 23.5 12.5C23.5 13.2 23.2583 13.7917 22.775 14.275C22.2917 14.7583 21.7 15 21 15V21H15.2C15.2 20.1667 14.9377 19.4583 14.413 18.875C13.8883 18.2917 13.2507 18 12.5 18C11.75 18 11.1127 18.2917 10.588 18.875C10.0633 19.4583 9.80067 20.1667 9.8 21ZM6 19H8.125C8.525 17.9 9.16667 17.125 10.05 16.675C10.9333 16.225 11.75 16 12.5 16C13.25 16 14.0667 16.225 14.95 16.675C15.8333 17.125 16.475 17.9 16.875 19H19V13H21C21.1333 13 21.25 12.95 21.35 12.85C21.45 12.75 21.5 12.6333 21.5 12.5C21.5 12.3667 21.45 12.25 21.35 12.15C21.25 12.05 21.1333 12 21 12H19V6H13V4C13 3.86667 12.95 3.75 12.85 3.65C12.75 3.55 12.6333 3.5 12.5 3.5C12.3667 3.5 12.25 3.55 12.15 3.65C12.05 3.75 12 3.86667 12 4V6H6V8.2C6.9 8.53333 7.625 9.09167 8.175 9.875C8.725 10.6583 9 11.5333 9 12.5C9 13.45 8.725 14.3167 8.175 15.1C7.625 15.8833 6.9 16.45 6 16.8V19Z"
        fill="currentColor"
      />
    </svg>
  );
}
