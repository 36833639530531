import { IconProps } from "./IconType";

export default function EyeDashIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="none"
      {...props}
    >
      <g clipPath="url(#clip0_4_744)">
        <path d="M15.6503 6.00847C14.5108 5.58606 13.2816 5.35577 12 5.35577C7.44984 5.35577 3.56109 8.25841 2 12.3558C2.64042 14.0367 3.67257 15.5165 4.98141 16.6773L6.39843 15.2603C5.47831 14.4669 4.71736 13.48 4.1755 12.3558C5.61369 9.37175 8.59528 7.35577 12 7.35577C12.7057 7.35577 13.3932 7.44237 14.0525 7.60624L15.6503 6.00847Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.186 10.3012L14.1887 10.304L16.0901 8.4026L16.0865 8.40064L17.5488 6.93838L17.5522 6.9405L20.1998 4.29289C20.5903 3.90237 21.2235 3.90237 21.614 4.29289C22.0046 4.68342 22.0046 5.31658 21.614 5.70711L19.1596 8.16152C20.4016 9.30245 21.3828 10.7357 22 12.3558C20.4389 16.4531 16.5502 19.3558 12 19.3558C10.792 19.3558 9.63069 19.1512 8.54713 18.774L5.61403 21.7071C5.2235 22.0976 4.59034 22.0976 4.19981 21.7071C3.80929 21.3166 3.80929 20.6834 4.19981 20.2929L6.61811 17.8746L6.61463 17.8725L8.085 16.4022L8.08866 16.404L9.94824 14.5445L9.94538 14.5418L14.186 10.3012ZM17.7442 9.57693L14.9998 12.3213L15 12.3558C15 14.0126 13.6568 15.3558 12 15.3558L11.9656 15.3556L10.1649 17.1563C10.7575 17.2871 11.3715 17.3558 12 17.3558C15.4047 17.3558 18.3863 15.3398 19.8245 12.3558C19.3109 11.2901 18.6005 10.3479 17.7442 9.57693Z"
        />
        <path d="M12.2892 9.36953C12.194 9.36043 12.0976 9.35577 12 9.35577C10.3431 9.35577 8.99999 10.6989 8.99999 12.3558C8.99999 12.4533 9.00465 12.5498 9.01375 12.645L12.2892 9.36953Z" />
      </g>
      <defs>
        <clipPath id="clip0_4_744">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
