import { IconProps } from "./IconType";

export default function ServerIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="none"
      {...props}
    >
      <g clipPath="url(#clip0_4_795)">
        <path d="M8 5.68421C7.62963 5.68421 7.31496 5.81484 7.056 6.07611C6.79644 6.33677 6.66667 6.65351 6.66667 7.02632C6.66667 7.39912 6.79644 7.71586 7.056 7.97653C7.31496 8.23779 7.62963 8.36842 8 8.36842C8.37037 8.36842 8.68504 8.23779 8.944 7.97653C9.20356 7.71586 9.33333 7.39912 9.33333 7.02632C9.33333 6.65351 9.20356 6.33677 8.944 6.07611C8.68504 5.81484 8.37037 5.68421 8 5.68421ZM8 14.6316C7.62963 14.6316 7.31496 14.7622 7.056 15.0235C6.79644 15.2841 6.66667 15.6009 6.66667 15.9737C6.66667 16.3465 6.79644 16.6632 7.056 16.9239C7.31496 17.1852 7.62963 17.3158 8 17.3158C8.37037 17.3158 8.68504 17.1852 8.944 16.9239C9.20356 16.6632 9.33333 16.3465 9.33333 15.9737C9.33333 15.6009 9.20356 15.2841 8.944 15.0235C8.68504 14.7622 8.37037 14.6316 8 14.6316ZM4.88889 3H19.1111C19.363 3 19.5739 3.0856 19.744 3.25679C19.9147 3.42858 20 3.64123 20 3.89474V10.1579C20 10.4114 19.9147 10.6238 19.744 10.7949C19.5739 10.9667 19.363 11.0526 19.1111 11.0526H4.88889C4.63704 11.0526 4.42607 10.9667 4.256 10.7949C4.08533 10.6238 4 10.4114 4 10.1579V3.89474C4 3.64123 4.08533 3.42858 4.256 3.25679C4.42607 3.0856 4.63704 3 4.88889 3ZM5.77778 4.78947V9.26316H18.2222V4.78947H5.77778ZM4.88889 11.9474H19.1111C19.363 11.9474 19.5739 12.033 19.744 12.2042C19.9147 12.3759 20 12.5886 20 12.8421V19.1053C20 19.3588 19.9147 19.5711 19.744 19.7423C19.5739 19.9141 19.363 20 19.1111 20H4.88889C4.63704 20 4.42607 19.9141 4.256 19.7423C4.08533 19.5711 4 19.3588 4 19.1053V12.8421C4 12.5886 4.08533 12.3759 4.256 12.2042C4.42607 12.033 4.63704 11.9474 4.88889 11.9474ZM5.77778 13.7368V18.2105H18.2222V13.7368H5.77778Z" />
      </g>
      <defs>
        <clipPath id="clip0_4_795">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
