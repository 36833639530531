import { IconProps } from "./IconType";

export default function ErrorLogFile(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path d="M4 14H9V12H4V14ZM14 14H16V12H14V14ZM4 10H9V8H4V10ZM14 10H16V4H14V10ZM4 6H9V4H4V6ZM2 18C1.45 18 0.979333 17.8043 0.588 17.413C0.196 17.021 0 16.55 0 16V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H2ZM2 16H18V2H2V16Z" />
    </svg>
  );
}
