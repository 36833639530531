import { IconProps } from "./IconType";

export default function CopyIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path d="M17 18H6C5.45 18 4.97933 17.8043 4.588 17.413C4.196 17.021 4 16.55 4 16V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H13L19 6V16C19 16.55 18.8043 17.021 18.413 17.413C18.021 17.8043 17.55 18 17 18ZM12 7V2H6V16H17V7H12ZM2 22C1.45 22 0.979333 21.8043 0.588 21.413C0.196 21.021 0 20.55 0 20V6H2V20H13V22H2Z" />
    </svg>
  );
}
