import { IconProps } from "./IconType";

export default function ForwardArrow(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path
        d="M9.9 19C8.28333 19 6.89567 18.475 5.737 17.425C4.57833 16.375 3.99933 15.0667 4 13.5C4 11.9333 4.57933 10.625 5.738 9.575C6.89667 8.525 8.284 8 9.9 8H16.2L13.6 5.4L15 4L20 9L15 14L13.6 12.6L16.2 10H9.9C8.85 10 7.93733 10.3333 7.162 11C6.38667 11.6667 5.99933 12.5 6 13.5C6 14.5 6.38767 15.3333 7.163 16C7.93833 16.6667 8.85067 17 9.9 17H17V19H9.9Z"
        fill="currentColor"
      />
    </svg>
  );
}
