import { IconProps } from "./IconType";

export default function OpenFlowIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path
        d="M2 20V4H10L12 6H22V8H11.175L9.175 6H4V18L6.4 10H23.5L20.5 20H2ZM6.1 18H19L20.8 12H7.9L6.1 18Z"
        fill="currentColor"
      />
    </svg>
  );
}
