import * as React from 'react';

// This module exists to work around Webpack issue https://github.com/webpack/webpack/issues/14814

// eslint-disable-next-line no-restricted-imports

const {
  createElement,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} = React;

// `toString()` prevents bundlers from trying to `import { useId } from 'react'`
const useId = React["useId".toString()];

const canUseEffectHooks = !!(typeof window !== "undefined" && typeof window.document !== "undefined" && typeof window.document.createElement !== "undefined");
const useIsomorphicLayoutEffect = canUseEffectHooks ? useLayoutEffect : () => {};

const wrappedUseId = typeof useId === "function" ? useId : () => null;
let counter = 0;
function useUniqueId(idFromParams = null) {
  const idFromUseId = wrappedUseId();
  const idRef = useRef(idFromParams || idFromUseId || null);
  if (idRef.current === null) {
    idRef.current = "" + counter++;
  }
  return idRef.current;
}

const PanelGroupContext = createContext(null);

// Workaround for Parcel scope hoisting (which renames objects/functions).
// Casting to :any is required to avoid corrupting the generated TypeScript types.
// See github.com/parcel-bundler/parcel/issues/8724
PanelGroupContext.displayName = "PanelGroupContext";

function PanelWithForwardedRef({
  children = null,
  className: classNameFromProps = "",
  collapsible = false,
  defaultSize = null,
  forwardedRef,
  id: idFromProps = null,
  maxSize = 100,
  minSize = 10,
  onCollapse = null,
  onResize = null,
  order = null,
  style: styleFromProps = {},
  tagName: Type = "div"
}) {
  const context = useContext(PanelGroupContext);
  if (context === null) {
    throw Error(`Panel components must be rendered within a PanelGroup container`);
  }
  const panelId = useUniqueId(idFromProps);
  const {
    collapsePanel,
    expandPanel,
    getPanelStyle,
    registerPanel,
    resizePanel,
    unregisterPanel
  } = context;

  // Use a ref to guard against users passing inline props
  const callbacksRef = useRef({
    onCollapse,
    onResize
  });
  useEffect(() => {
    callbacksRef.current.onCollapse = onCollapse;
    callbacksRef.current.onResize = onResize;
  });

  // Basic props validation
  if (minSize < 0 || minSize > 100) {
    throw Error(`Panel minSize must be between 0 and 100, but was ${minSize}`);
  } else if (maxSize < 0 || maxSize > 100) {
    throw Error(`Panel maxSize must be between 0 and 100, but was ${maxSize}`);
  } else {
    if (defaultSize !== null) {
      if (defaultSize < 0 || defaultSize > 100) {
        throw Error(`Panel defaultSize must be between 0 and 100, but was ${defaultSize}`);
      } else if (minSize > defaultSize && !collapsible) {
        console.error(`Panel minSize ${minSize} cannot be greater than defaultSize ${defaultSize}`);
        defaultSize = minSize;
      }
    }
  }
  const style = getPanelStyle(panelId, defaultSize);
  const committedValuesRef = useRef({
    size: parseSizeFromStyle(style)
  });
  const panelDataRef = useRef({
    callbacksRef,
    collapsible,
    defaultSize,
    id: panelId,
    maxSize,
    minSize,
    order
  });
  useIsomorphicLayoutEffect(() => {
    committedValuesRef.current.size = parseSizeFromStyle(style);
    panelDataRef.current.callbacksRef = callbacksRef;
    panelDataRef.current.collapsible = collapsible;
    panelDataRef.current.defaultSize = defaultSize;
    panelDataRef.current.id = panelId;
    panelDataRef.current.maxSize = maxSize;
    panelDataRef.current.minSize = minSize;
    panelDataRef.current.order = order;
  });
  useIsomorphicLayoutEffect(() => {
    registerPanel(panelId, panelDataRef);
    return () => {
      unregisterPanel(panelId);
    };
  }, [order, panelId, registerPanel, unregisterPanel]);
  useImperativeHandle(forwardedRef, () => ({
    collapse: () => collapsePanel(panelId),
    expand: () => expandPanel(panelId),
    getCollapsed() {
      return committedValuesRef.current.size === 0;
    },
    getSize() {
      return committedValuesRef.current.size;
    },
    resize: percentage => resizePanel(panelId, percentage)
  }), [collapsePanel, expandPanel, panelId, resizePanel]);
  return createElement(Type, {
    children,
    className: classNameFromProps,
    "data-panel": "",
    "data-panel-collapsible": collapsible || undefined,
    "data-panel-id": panelId,
    "data-panel-size": parseFloat("" + style.flexGrow).toFixed(1),
    id: `data-panel-id-${panelId}`,
    style: {
      ...style,
      ...styleFromProps
    }
  });
}
const Panel = forwardRef((props, ref) => createElement(PanelWithForwardedRef, {
  ...props,
  forwardedRef: ref
}));

// Workaround for Parcel scope hoisting (which renames objects/functions).
// Casting to :any is required to avoid corrupting the generated TypeScript types.
// See github.com/parcel-bundler/parcel/issues/8724
PanelWithForwardedRef.displayName = "Panel";
Panel.displayName = "forwardRef(Panel)";

// HACK
function parseSizeFromStyle(style) {
  const {
    flexGrow
  } = style;
  if (typeof flexGrow === "string") {
    return parseFloat(flexGrow);
  } else {
    return flexGrow;
  }
}

const PRECISION = 10;

function adjustByDelta(event, panels, idBefore, idAfter, delta, prevSizes, panelSizeBeforeCollapse, initialDragState) {
  const {
    sizes: initialSizes
  } = initialDragState || {};

  // If we're resizing by mouse or touch, use the initial sizes as a base.
  // This has the benefit of causing force-collapsed panels to spring back open if drag is reversed.
  const baseSizes = initialSizes || prevSizes;
  if (delta === 0) {
    return baseSizes;
  }
  const panelsArray = panelsMapToSortedArray(panels);
  const nextSizes = baseSizes.concat();
  let deltaApplied = 0;

  // A resizing panel affects the panels before or after it.
  //
  // A negative delta means the panel immediately after the resizer should grow/expand by decreasing its offset.
  // Other panels may also need to shrink/contract (and shift) to make room, depending on the min weights.
  //
  // A positive delta means the panel immediately before the resizer should "expand".
  // This is accomplished by shrinking/contracting (and shifting) one or more of the panels after the resizer.

  // Max-bounds check the panel being expanded first.
  {
    const pivotId = delta < 0 ? idAfter : idBefore;
    const index = panelsArray.findIndex(panel => panel.current.id === pivotId);
    const panel = panelsArray[index];
    const baseSize = baseSizes[index];
    const nextSize = safeResizePanel(panel, Math.abs(delta), baseSize, event);
    if (baseSize === nextSize) {
      // If there's no room for the pivot panel to grow, we can ignore this drag update.
      return baseSizes;
    } else {
      if (nextSize === 0 && baseSize > 0) {
        panelSizeBeforeCollapse.set(pivotId, baseSize);
      }
      delta = delta < 0 ? baseSize - nextSize : nextSize - baseSize;
    }
  }
  let pivotId = delta < 0 ? idBefore : idAfter;
  let index = panelsArray.findIndex(panel => panel.current.id === pivotId);
  while (true) {
    const panel = panelsArray[index];
    const baseSize = baseSizes[index];
    const deltaRemaining = Math.abs(delta) - Math.abs(deltaApplied);
    const nextSize = safeResizePanel(panel, 0 - deltaRemaining, baseSize, event);
    if (baseSize !== nextSize) {
      if (nextSize === 0 && baseSize > 0) {
        panelSizeBeforeCollapse.set(panel.current.id, baseSize);
      }
      deltaApplied += baseSize - nextSize;
      nextSizes[index] = nextSize;
      if (deltaApplied.toPrecision(PRECISION).localeCompare(Math.abs(delta).toPrecision(PRECISION), undefined, {
        numeric: true
      }) >= 0) {
        break;
      }
    }
    if (delta < 0) {
      if (--index < 0) {
        break;
      }
    } else {
      if (++index >= panelsArray.length) {
        break;
      }
    }
  }

  // If we were unable to resize any of the panels panels, return the previous state.
  // This will essentially bailout and ignore the "mousemove" event.
  if (deltaApplied === 0) {
    return baseSizes;
  }

  // Adjust the pivot panel before, but only by the amount that surrounding panels were able to shrink/contract.
  pivotId = delta < 0 ? idAfter : idBefore;
  index = panelsArray.findIndex(panel => panel.current.id === pivotId);
  nextSizes[index] = baseSizes[index] + deltaApplied;
  return nextSizes;
}
function callPanelCallbacks(panelsArray, sizes, panelIdToLastNotifiedSizeMap) {
  sizes.forEach((size, index) => {
    const {
      callbacksRef,
      collapsible,
      id
    } = panelsArray[index].current;
    const lastNotifiedSize = panelIdToLastNotifiedSizeMap[id];
    if (lastNotifiedSize !== size) {
      panelIdToLastNotifiedSizeMap[id] = size;
      const {
        onCollapse,
        onResize
      } = callbacksRef.current;
      if (onResize) {
        onResize(size);
      }
      if (collapsible && onCollapse) {
        // Falsy check handles both previous size of 0
        // and initial size of undefined (when mounting)
        if (!lastNotifiedSize && size !== 0) {
          onCollapse(false);
        } else if (lastNotifiedSize !== 0 && size === 0) {
          onCollapse(true);
        }
      }
    }
  });
}
function getBeforeAndAfterIds(id, panelsArray) {
  if (panelsArray.length < 2) {
    return [null, null];
  }
  const index = panelsArray.findIndex(panel => panel.current.id === id);
  if (index < 0) {
    return [null, null];
  }
  const isLastPanel = index === panelsArray.length - 1;
  const idBefore = isLastPanel ? panelsArray[index - 1].current.id : id;
  const idAfter = isLastPanel ? id : panelsArray[index + 1].current.id;
  return [idBefore, idAfter];
}

// This method returns a number between 1 and 100 representing
// the % of the group's overall space this panel should occupy.
function getFlexGrow(panels, id, sizes) {
  if (panels.size === 1) {
    return "100";
  }
  const panelsArray = panelsMapToSortedArray(panels);
  const index = panelsArray.findIndex(panel => panel.current.id === id);
  const size = sizes[index];
  if (size == null) {
    return "0";
  }
  return size.toPrecision(PRECISION);
}
function getPanel(id) {
  const element = document.querySelector(`[data-panel-id="${id}"]`);
  if (element) {
    return element;
  }
  return null;
}
function getPanelGroup(id) {
  const element = document.querySelector(`[data-panel-group-id="${id}"]`);
  if (element) {
    return element;
  }
  return null;
}
function getResizeHandle(id) {
  const element = document.querySelector(`[data-panel-resize-handle-id="${id}"]`);
  if (element) {
    return element;
  }
  return null;
}
function getResizeHandleIndex(id) {
  const handles = getResizeHandles();
  const index = handles.findIndex(handle => handle.getAttribute("data-panel-resize-handle-id") === id);
  return index ?? null;
}
function getResizeHandles() {
  return Array.from(document.querySelectorAll(`[data-panel-resize-handle-id]`));
}
function getResizeHandlesForGroup(groupId) {
  return Array.from(document.querySelectorAll(`[data-panel-resize-handle-id][data-panel-group-id="${groupId}"]`));
}
function getResizeHandlePanelIds(groupId, handleId, panelsArray) {
  const handle = getResizeHandle(handleId);
  const handles = getResizeHandlesForGroup(groupId);
  const index = handle ? handles.indexOf(handle) : -1;
  const idBefore = panelsArray[index]?.current?.id ?? null;
  const idAfter = panelsArray[index + 1]?.current?.id ?? null;
  return [idBefore, idAfter];
}
function panelsMapToSortedArray(panels) {
  return Array.from(panels.values()).sort((panelA, panelB) => {
    const orderA = panelA.current.order;
    const orderB = panelB.current.order;
    if (orderA == null && orderB == null) {
      return 0;
    } else if (orderA == null) {
      return -1;
    } else if (orderB == null) {
      return 1;
    } else {
      return orderA - orderB;
    }
  });
}
function safeResizePanel(panel, delta, prevSize, event) {
  const nextSizeUnsafe = prevSize + delta;
  if (panel.current.collapsible) {
    if (prevSize > 0) {
      // Mimic VS COde behavior; collapse a panel if it's smaller than half of its min-size
      if (nextSizeUnsafe <= panel.current.minSize / 2) {
        return 0;
      }
    } else {
      const isKeyboardEvent = event?.type?.startsWith("key");
      if (!isKeyboardEvent) {
        // Keyboard events should expand a collapsed panel to the min size,
        // but mouse events should wait until the panel has reached its min size
        // to avoid a visual flickering when dragging between collapsed and min size.
        if (nextSizeUnsafe < panel.current.minSize) {
          return 0;
        }
      }
    }
  }
  const nextSize = Math.min(panel.current.maxSize, Math.max(panel.current.minSize, nextSizeUnsafe));
  return nextSize;
}

function assert(expectedCondition, message = "Assertion failed!") {
  if (!expectedCondition) {
    console.error(message);
    throw Error(message);
  }
}

// https://www.w3.org/WAI/ARIA/apg/patterns/windowsplitter/

function useWindowSplitterPanelGroupBehavior({
  committedValuesRef,
  groupId,
  panels,
  setSizes,
  sizes,
  panelSizeBeforeCollapse
}) {
  useEffect(() => {
    const {
      direction,
      panels
    } = committedValuesRef.current;
    const groupElement = getPanelGroup(groupId);
    const {
      height,
      width
    } = groupElement.getBoundingClientRect();
    const handles = getResizeHandlesForGroup(groupId);
    const cleanupFunctions = handles.map(handle => {
      const handleId = handle.getAttribute("data-panel-resize-handle-id");
      const panelsArray = panelsMapToSortedArray(panels);
      const [idBefore, idAfter] = getResizeHandlePanelIds(groupId, handleId, panelsArray);
      if (idBefore == null || idAfter == null) {
        return () => {};
      }
      let minSize = 0;
      let maxSize = 100;
      let totalMinSize = 0;
      let totalMaxSize = 0;

      // A panel's effective min/max sizes also need to account for other panel's sizes.
      panelsArray.forEach(panelData => {
        if (panelData.current.id === idBefore) {
          maxSize = panelData.current.maxSize;
          minSize = panelData.current.minSize;
        } else {
          totalMinSize += panelData.current.minSize;
          totalMaxSize += panelData.current.maxSize;
        }
      });
      const ariaValueMax = Math.min(maxSize, 100 - totalMinSize);
      const ariaValueMin = Math.max(minSize, (panelsArray.length - 1) * 100 - totalMaxSize);
      const flexGrow = getFlexGrow(panels, idBefore, sizes);
      handle.setAttribute("aria-valuemax", "" + Math.round(ariaValueMax));
      handle.setAttribute("aria-valuemin", "" + Math.round(ariaValueMin));
      handle.setAttribute("aria-valuenow", "" + Math.round(parseInt(flexGrow)));
      const onKeyDown = event => {
        if (event.defaultPrevented) {
          return;
        }
        switch (event.key) {
          case "Enter":
            {
              event.preventDefault();
              const index = panelsArray.findIndex(panel => panel.current.id === idBefore);
              if (index >= 0) {
                const panelData = panelsArray[index];
                const size = sizes[index];
                if (size != null) {
                  let delta = 0;
                  if (size.toPrecision(PRECISION) <= panelData.current.minSize.toPrecision(PRECISION)) {
                    delta = direction === "horizontal" ? width : height;
                  } else {
                    delta = -(direction === "horizontal" ? width : height);
                  }
                  const nextSizes = adjustByDelta(event, panels, idBefore, idAfter, delta, sizes, panelSizeBeforeCollapse.current, null);
                  if (sizes !== nextSizes) {
                    setSizes(nextSizes);
                  }
                }
              }
              break;
            }
        }
      };
      handle.addEventListener("keydown", onKeyDown);
      const panelBefore = getPanel(idBefore);
      if (panelBefore != null) {
        handle.setAttribute("aria-controls", panelBefore.id);
      }
      return () => {
        handle.removeAttribute("aria-valuemax");
        handle.removeAttribute("aria-valuemin");
        handle.removeAttribute("aria-valuenow");
        handle.removeEventListener("keydown", onKeyDown);
        if (panelBefore != null) {
          handle.removeAttribute("aria-controls");
        }
      };
    });
    return () => {
      cleanupFunctions.forEach(cleanupFunction => cleanupFunction());
    };
  }, [committedValuesRef, groupId, panels, panelSizeBeforeCollapse, setSizes, sizes]);
}
function useWindowSplitterResizeHandlerBehavior({
  disabled,
  handleId,
  resizeHandler
}) {
  useEffect(() => {
    if (disabled || resizeHandler == null) {
      return;
    }
    const handleElement = getResizeHandle(handleId);
    if (handleElement == null) {
      return;
    }
    const onKeyDown = event => {
      if (event.defaultPrevented) {
        return;
      }
      switch (event.key) {
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight":
        case "ArrowUp":
        case "End":
        case "Home":
          {
            event.preventDefault();
            resizeHandler(event);
            break;
          }
        case "F6":
          {
            event.preventDefault();
            const handles = getResizeHandles();
            const index = getResizeHandleIndex(handleId);
            assert(index !== null);
            const nextIndex = event.shiftKey ? index > 0 ? index - 1 : handles.length - 1 : index + 1 < handles.length ? index + 1 : 0;
            const nextHandle = handles[nextIndex];
            nextHandle.focus();
            break;
          }
      }
    };
    handleElement.addEventListener("keydown", onKeyDown);
    return () => {
      handleElement.removeEventListener("keydown", onKeyDown);
    };
  }, [disabled, handleId, resizeHandler]);
}

function areEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (let index = 0; index < arrayA.length; index++) {
    if (arrayA[index] !== arrayB[index]) {
      return false;
    }
  }
  return true;
}

function getDragOffset(event, handleId, direction, initialOffset = 0, initialHandleElementRect = null) {
  const isHorizontal = direction === "horizontal";
  let pointerOffset = 0;
  if (isMouseEvent(event)) {
    pointerOffset = isHorizontal ? event.clientX : event.clientY;
  } else if (isTouchEvent(event)) {
    const firstTouch = event.touches[0];
    pointerOffset = isHorizontal ? firstTouch.screenX : firstTouch.screenY;
  } else {
    return 0;
  }
  const handleElement = getResizeHandle(handleId);
  const rect = initialHandleElementRect || handleElement.getBoundingClientRect();
  const elementOffset = isHorizontal ? rect.left : rect.top;
  return pointerOffset - elementOffset - initialOffset;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/movementX
function getMovement(event, groupId, handleId, panelsArray, direction, prevSizes, initialDragState) {
  const {
    dragOffset = 0,
    dragHandleRect,
    sizes: initialSizes
  } = initialDragState || {};

  // If we're resizing by mouse or touch, use the initial sizes as a base.
  // This has the benefit of causing force-collapsed panels to spring back open if drag is reversed.
  const baseSizes = initialSizes || prevSizes;
  if (isKeyDown(event)) {
    const isHorizontal = direction === "horizontal";
    const groupElement = getPanelGroup(groupId);
    const rect = groupElement.getBoundingClientRect();
    const groupSizeInPixels = isHorizontal ? rect.width : rect.height;
    const denominator = event.shiftKey ? 10 : 100;
    const delta = groupSizeInPixels / denominator;
    let movement = 0;
    switch (event.key) {
      case "ArrowDown":
        movement = isHorizontal ? 0 : delta;
        break;
      case "ArrowLeft":
        movement = isHorizontal ? -delta : 0;
        break;
      case "ArrowRight":
        movement = isHorizontal ? delta : 0;
        break;
      case "ArrowUp":
        movement = isHorizontal ? 0 : -delta;
        break;
      case "End":
        movement = groupSizeInPixels;
        break;
      case "Home":
        movement = -groupSizeInPixels;
        break;
    }

    // If the Panel being resized is collapsible,
    // we need to special case resizing around the minSize boundary.
    // If contracting, Panels should shrink to their minSize and then snap to fully collapsed.
    // If expanding from collapsed, they should snap back to their minSize.
    const [idBefore, idAfter] = getResizeHandlePanelIds(groupId, handleId, panelsArray);
    const targetPanelId = movement < 0 ? idBefore : idAfter;
    const targetPanelIndex = panelsArray.findIndex(panel => panel.current.id === targetPanelId);
    const targetPanel = panelsArray[targetPanelIndex];
    if (targetPanel.current.collapsible) {
      const baseSize = baseSizes[targetPanelIndex];
      if (baseSize === 0 || baseSize.toPrecision(PRECISION) === targetPanel.current.minSize.toPrecision(PRECISION)) {
        movement = movement < 0 ? -targetPanel.current.minSize * groupSizeInPixels : targetPanel.current.minSize * groupSizeInPixels;
      }
    }
    return movement;
  } else {
    return getDragOffset(event, handleId, direction, dragOffset, dragHandleRect);
  }
}
function isKeyDown(event) {
  return event.type === "keydown";
}
function isMouseEvent(event) {
  return event.type.startsWith("mouse");
}
function isTouchEvent(event) {
  return event.type.startsWith("touch");
}

let currentState = null;
let element = null;
function getCursorStyle(state) {
  switch (state) {
    case "horizontal":
      return "ew-resize";
    case "horizontal-max":
      return "w-resize";
    case "horizontal-min":
      return "e-resize";
    case "vertical":
      return "ns-resize";
    case "vertical-max":
      return "n-resize";
    case "vertical-min":
      return "s-resize";
  }
}
function resetGlobalCursorStyle() {
  if (element !== null) {
    document.head.removeChild(element);
    currentState = null;
    element = null;
  }
}
function setGlobalCursorStyle(state) {
  if (currentState === state) {
    return;
  }
  currentState = state;
  const style = getCursorStyle(state);
  if (element === null) {
    element = document.createElement("style");
    document.head.appendChild(element);
  }
  element.innerHTML = `*{cursor: ${style}!important;}`;
}

function debounce(callback, durationMs = 10) {
  let timeoutId = null;
  let callable = (...args) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback(...args);
    }, durationMs);
  };
  return callable;
}

// Note that Panel ids might be user-provided (stable) or useId generated (non-deterministic)
// so they should not be used as part of the serialization key.
// Using an attribute like minSize instead should work well enough.
// Pre-sorting by minSize allows remembering layouts even if panels are re-ordered/dragged.
function getSerializationKey(panels) {
  return panels.map(panel => {
    const {
      minSize,
      order
    } = panel.current;
    return order ? `${order}:${minSize}` : `${minSize}`;
  }).sort((a, b) => a.localeCompare(b)).join(",");
}
function loadSerializedPanelGroupState(autoSaveId, storage) {
  try {
    const serialized = storage.getItem(`PanelGroup:sizes:${autoSaveId}`);
    if (serialized) {
      const parsed = JSON.parse(serialized);
      if (typeof parsed === "object" && parsed != null) {
        return parsed;
      }
    }
  } catch (error) {}
  return null;
}
function loadPanelLayout(autoSaveId, panels, storage) {
  const state = loadSerializedPanelGroupState(autoSaveId, storage);
  if (state) {
    const key = getSerializationKey(panels);
    return state[key] ?? null;
  }
  return null;
}
function savePanelGroupLayout(autoSaveId, panels, sizes, storage) {
  const key = getSerializationKey(panels);
  const state = loadSerializedPanelGroupState(autoSaveId, storage) || {};
  state[key] = sizes;
  try {
    storage.setItem(`PanelGroup:sizes:${autoSaveId}`, JSON.stringify(state));
  } catch (error) {
    console.error(error);
  }
}

const debounceMap = {};

// PanelGroup might be rendering in a server-side environment where localStorage is not available
// or on a browser with cookies/storage disabled.
// In either case, this function avoids accessing localStorage until needed,
// and avoids throwing user-visible errors.
function initializeDefaultStorage(storageObject) {
  try {
    if (typeof localStorage !== "undefined") {
      // Bypass this check for future calls
      storageObject.getItem = name => {
        return localStorage.getItem(name);
      };
      storageObject.setItem = (name, value) => {
        localStorage.setItem(name, value);
      };
    } else {
      throw new Error("localStorage not supported in this environment");
    }
  } catch (error) {
    console.error(error);
    storageObject.getItem = () => null;
    storageObject.setItem = () => {};
  }
}
const defaultStorage = {
  getItem: name => {
    initializeDefaultStorage(defaultStorage);
    return defaultStorage.getItem(name);
  },
  setItem: (name, value) => {
    initializeDefaultStorage(defaultStorage);
    defaultStorage.setItem(name, value);
  }
};

// Initial drag state serves a few purposes:
// * dragOffset:
//   Resize is calculated by the distance between the current pointer event and the resize handle being "dragged"
//   This value accounts for the initial offset when the touch/click starts, so the handle doesn't appear to "jump"
// * dragHandleRect, sizes:
//   When resizing is done via mouse/touch event– some initial state is stored
//   so that any panels that contract will also expand if drag direction is reversed.
// TODO
// Within an active drag, remember original positions to refine more easily on expand.
// Look at what the Chrome devtools Sources does.
function PanelGroupWithForwardedRef({
  autoSaveId,
  children = null,
  className: classNameFromProps = "",
  direction,
  disablePointerEventsDuringResize = false,
  forwardedRef,
  id: idFromProps = null,
  onLayout,
  storage = defaultStorage,
  style: styleFromProps = {},
  tagName: Type = "div"
}) {
  const groupId = useUniqueId(idFromProps);
  const [activeHandleId, setActiveHandleId] = useState(null);
  const [panels, setPanels] = useState(new Map());

  // When resizing is done via mouse/touch event–
  // We store the initial Panel sizes in this ref, and apply move deltas to them instead of to the current sizes.
  // This has the benefit of causing force-collapsed panels to spring back open if drag is reversed.
  const initialDragStateRef = useRef(null);

  // Use a ref to guard against users passing inline props
  const callbacksRef = useRef({
    onLayout
  });
  useEffect(() => {
    callbacksRef.current.onLayout = onLayout;
  });
  const panelIdToLastNotifiedSizeMapRef = useRef({});

  // 0-1 values representing the relative size of each panel.
  const [sizes, setSizes] = useState([]);

  // Used to support imperative collapse/expand API.
  const panelSizeBeforeCollapse = useRef(new Map());
  const prevDeltaRef = useRef(0);

  // Store committed values to avoid unnecessarily re-running memoization/effects functions.
  const committedValuesRef = useRef({
    direction,
    panels,
    sizes
  });
  useImperativeHandle(forwardedRef, () => ({
    getLayout: () => {
      const {
        sizes
      } = committedValuesRef.current;
      return sizes;
    },
    setLayout: sizes => {
      const total = sizes.reduce((accumulated, current) => accumulated + current, 0);
      assert(total === 100, "Panel sizes must add up to 100%");
      const {
        panels
      } = committedValuesRef.current;
      const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;
      const panelsArray = panelsMapToSortedArray(panels);
      callPanelCallbacks(panelsArray, sizes, panelIdToLastNotifiedSizeMap);
      setSizes(sizes);
    }
  }), []);
  useIsomorphicLayoutEffect(() => {
    committedValuesRef.current.direction = direction;
    committedValuesRef.current.panels = panels;
    committedValuesRef.current.sizes = sizes;
  });
  useWindowSplitterPanelGroupBehavior({
    committedValuesRef,
    groupId,
    panels,
    setSizes,
    sizes,
    panelSizeBeforeCollapse
  });

  // Notify external code when sizes have changed.
  useEffect(() => {
    const {
      onLayout
    } = callbacksRef.current;
    if (onLayout) {
      const {
        panels,
        sizes
      } = committedValuesRef.current;

      // Don't commit layout until all panels have registered and re-rendered with their actual sizes.
      if (sizes.length > 0) {
        onLayout(sizes);
        const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;

        // When possible, we notify before the next render so that rendering work can be batched together.
        // Some cases are difficult to detect though,
        // for example– panels that are conditionally rendered can affect the size of neighboring panels.
        // In this case, the best we can do is notify on commit.
        // The callPanelCallbacks() uses its own memoization to avoid notifying panels twice in these cases.
        const panelsArray = panelsMapToSortedArray(panels);
        callPanelCallbacks(panelsArray, sizes, panelIdToLastNotifiedSizeMap);
      }
    }
  }, [sizes]);

  // Once all panels have registered themselves,
  // Compute the initial sizes based on default weights.
  // This assumes that panels register during initial mount (no conditional rendering)!
  useIsomorphicLayoutEffect(() => {
    const sizes = committedValuesRef.current.sizes;
    if (sizes.length === panels.size) {
      // Only compute (or restore) default sizes once per panel configuration.
      return;
    }

    // If this panel has been configured to persist sizing information,
    // default size should be restored from local storage if possible.
    let defaultSizes = null;
    if (autoSaveId) {
      const panelsArray = panelsMapToSortedArray(panels);
      defaultSizes = loadPanelLayout(autoSaveId, panelsArray, storage);
    }
    if (defaultSizes != null) {
      setSizes(defaultSizes);
    } else {
      const panelsArray = panelsMapToSortedArray(panels);
      let panelsWithNullDefaultSize = 0;
      let totalDefaultSize = 0;
      let totalMinSize = 0;

      // TODO
      // Implicit default size calculations below do not account for inferred min/max size values.
      // e.g. if Panel A has a maxSize of 40 then Panels A and B can't both have an implicit default size of 50.
      // For now, these logic edge cases are left to the user to handle via props.

      panelsArray.forEach(panel => {
        totalMinSize += panel.current.minSize;
        if (panel.current.defaultSize === null) {
          panelsWithNullDefaultSize++;
        } else {
          totalDefaultSize += panel.current.defaultSize;
        }
      });
      if (totalDefaultSize > 100) {
        throw new Error(`Default panel sizes cannot exceed 100%`);
      } else if (panelsArray.length > 1 && panelsWithNullDefaultSize === 0 && totalDefaultSize !== 100) {
        throw new Error(`Invalid default sizes specified for panels`);
      } else if (totalMinSize > 100) {
        throw new Error(`Minimum panel sizes cannot exceed 100%`);
      }
      setSizes(panelsArray.map(panel => {
        if (panel.current.defaultSize === null) {
          return (100 - totalDefaultSize) / panelsWithNullDefaultSize;
        }
        return panel.current.defaultSize;
      }));
    }
  }, [autoSaveId, panels, storage]);
  useEffect(() => {
    // If this panel has been configured to persist sizing information, save sizes to local storage.
    if (autoSaveId) {
      if (sizes.length === 0 || sizes.length !== panels.size) {
        return;
      }
      const panelsArray = panelsMapToSortedArray(panels);

      // Limit the frequency of localStorage updates.
      if (!debounceMap[autoSaveId]) {
        debounceMap[autoSaveId] = debounce(savePanelGroupLayout, 100);
      }
      debounceMap[autoSaveId](autoSaveId, panelsArray, sizes, storage);
    }
  }, [autoSaveId, panels, sizes, storage]);
  const getPanelStyle = useCallback((id, defaultSize) => {
    const {
      panels
    } = committedValuesRef.current;

    // Before mounting, Panels will not yet have registered themselves.
    // This includes server rendering.
    // At this point the best we can do is render everything with the same size.
    if (panels.size === 0) {
      return {
        flexBasis: 0,
        flexGrow: defaultSize != null ? defaultSize : undefined,
        flexShrink: 1,
        // Without this, Panel sizes may be unintentionally overridden by their content.
        overflow: "hidden"
      };
    }
    const flexGrow = getFlexGrow(panels, id, sizes);
    return {
      flexBasis: 0,
      flexGrow,
      flexShrink: 1,
      // Without this, Panel sizes may be unintentionally overridden by their content.
      overflow: "hidden",
      // Disable pointer events inside of a panel during resize.
      // This avoid edge cases like nested iframes.
      pointerEvents: disablePointerEventsDuringResize && activeHandleId !== null ? "none" : undefined
    };
  }, [activeHandleId, disablePointerEventsDuringResize, sizes]);
  const registerPanel = useCallback((id, panelRef) => {
    setPanels(prevPanels => {
      if (prevPanels.has(id)) {
        return prevPanels;
      }
      const nextPanels = new Map(prevPanels);
      nextPanels.set(id, panelRef);
      return nextPanels;
    });
  }, []);
  const registerResizeHandle = useCallback(handleId => {
    const resizeHandler = event => {
      event.preventDefault();
      const {
        direction,
        panels,
        sizes: prevSizes
      } = committedValuesRef.current;
      const panelsArray = panelsMapToSortedArray(panels);
      const [idBefore, idAfter] = getResizeHandlePanelIds(groupId, handleId, panelsArray);
      if (idBefore == null || idAfter == null) {
        return;
      }
      let movement = getMovement(event, groupId, handleId, panelsArray, direction, prevSizes, initialDragStateRef.current);
      if (movement === 0) {
        return;
      }
      const groupElement = getPanelGroup(groupId);
      const rect = groupElement.getBoundingClientRect();
      const isHorizontal = direction === "horizontal";

      // Support RTL layouts
      if (document.dir === "rtl" && isHorizontal) {
        movement = -movement;
      }
      const size = isHorizontal ? rect.width : rect.height;
      const delta = movement / size * 100;
      const nextSizes = adjustByDelta(event, panels, idBefore, idAfter, delta, prevSizes, panelSizeBeforeCollapse.current, initialDragStateRef.current);
      const sizesChanged = !areEqual(prevSizes, nextSizes);

      // Don't update cursor for resizes triggered by keyboard interactions.
      if (isMouseEvent(event) || isTouchEvent(event)) {
        // Watch for multiple subsequent deltas; this might occur for tiny cursor movements.
        // In this case, Panel sizes might not change–
        // but updating cursor in this scenario would cause a flicker.
        if (prevDeltaRef.current != delta) {
          if (!sizesChanged) {
            // If the pointer has moved too far to resize the panel any further,
            // update the cursor style for a visual clue.
            // This mimics VS Code behavior.

            if (isHorizontal) {
              setGlobalCursorStyle(movement < 0 ? "horizontal-min" : "horizontal-max");
            } else {
              setGlobalCursorStyle(movement < 0 ? "vertical-min" : "vertical-max");
            }
          } else {
            // Reset the cursor style to the the normal resize cursor.
            setGlobalCursorStyle(isHorizontal ? "horizontal" : "vertical");
          }
        }
      }
      if (sizesChanged) {
        const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;

        // If resize change handlers have been declared, this is the time to call them.
        callPanelCallbacks(panelsArray, nextSizes, panelIdToLastNotifiedSizeMap);
        setSizes(nextSizes);
      }
      prevDeltaRef.current = delta;
    };
    return resizeHandler;
  }, [groupId]);
  const unregisterPanel = useCallback(id => {
    setPanels(prevPanels => {
      if (!prevPanels.has(id)) {
        return prevPanels;
      }
      const nextPanels = new Map(prevPanels);
      nextPanels.delete(id);
      return nextPanels;
    });
  }, []);
  const collapsePanel = useCallback(id => {
    const {
      panels,
      sizes: prevSizes
    } = committedValuesRef.current;
    const panel = panels.get(id);
    if (panel == null || !panel.current.collapsible) {
      return;
    }
    const panelsArray = panelsMapToSortedArray(panels);
    const index = panelsArray.indexOf(panel);
    if (index < 0) {
      return;
    }
    const currentSize = prevSizes[index];
    if (currentSize === 0) {
      // Panel is already collapsed.
      return;
    }
    panelSizeBeforeCollapse.current.set(id, currentSize);
    const [idBefore, idAfter] = getBeforeAndAfterIds(id, panelsArray);
    if (idBefore == null || idAfter == null) {
      return;
    }
    const isLastPanel = index === panelsArray.length - 1;
    const delta = isLastPanel ? currentSize : 0 - currentSize;
    const nextSizes = adjustByDelta(null, panels, idBefore, idAfter, delta, prevSizes, panelSizeBeforeCollapse.current, null);
    if (prevSizes !== nextSizes) {
      const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;

      // If resize change handlers have been declared, this is the time to call them.
      callPanelCallbacks(panelsArray, nextSizes, panelIdToLastNotifiedSizeMap);
      setSizes(nextSizes);
    }
  }, []);
  const expandPanel = useCallback(id => {
    const {
      panels,
      sizes: prevSizes
    } = committedValuesRef.current;
    const panel = panels.get(id);
    if (panel == null) {
      return;
    }
    const sizeBeforeCollapse = panelSizeBeforeCollapse.current.get(id) || panel.current.minSize;
    if (!sizeBeforeCollapse) {
      return;
    }
    const panelsArray = panelsMapToSortedArray(panels);
    const index = panelsArray.indexOf(panel);
    if (index < 0) {
      return;
    }
    const currentSize = prevSizes[index];
    if (currentSize !== 0) {
      // Panel is already expanded.
      return;
    }
    const [idBefore, idAfter] = getBeforeAndAfterIds(id, panelsArray);
    if (idBefore == null || idAfter == null) {
      return;
    }
    const isLastPanel = index === panelsArray.length - 1;
    const delta = isLastPanel ? 0 - sizeBeforeCollapse : sizeBeforeCollapse;
    const nextSizes = adjustByDelta(null, panels, idBefore, idAfter, delta, prevSizes, panelSizeBeforeCollapse.current, null);
    if (prevSizes !== nextSizes) {
      const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;

      // If resize change handlers have been declared, this is the time to call them.
      callPanelCallbacks(panelsArray, nextSizes, panelIdToLastNotifiedSizeMap);
      setSizes(nextSizes);
    }
  }, []);
  const resizePanel = useCallback((id, nextSize) => {
    const {
      panels,
      sizes: prevSizes
    } = committedValuesRef.current;
    const panel = panels.get(id);
    if (panel == null) {
      return;
    }
    const panelsArray = panelsMapToSortedArray(panels);
    const index = panelsArray.indexOf(panel);
    if (index < 0) {
      return;
    }
    const currentSize = prevSizes[index];
    if (currentSize === nextSize) {
      return;
    }
    if (panel.current.collapsible && nextSize === 0) ; else {
      nextSize = Math.min(panel.current.maxSize, Math.max(panel.current.minSize, nextSize));
    }
    const [idBefore, idAfter] = getBeforeAndAfterIds(id, panelsArray);
    if (idBefore == null || idAfter == null) {
      return;
    }
    const isLastPanel = index === panelsArray.length - 1;
    const delta = isLastPanel ? currentSize - nextSize : nextSize - currentSize;
    const nextSizes = adjustByDelta(null, panels, idBefore, idAfter, delta, prevSizes, panelSizeBeforeCollapse.current, null);
    if (prevSizes !== nextSizes) {
      const panelIdToLastNotifiedSizeMap = panelIdToLastNotifiedSizeMapRef.current;

      // If resize change handlers have been declared, this is the time to call them.
      callPanelCallbacks(panelsArray, nextSizes, panelIdToLastNotifiedSizeMap);
      setSizes(nextSizes);
    }
  }, []);
  const context = useMemo(() => ({
    activeHandleId,
    collapsePanel,
    direction,
    expandPanel,
    getPanelStyle,
    groupId,
    registerPanel,
    registerResizeHandle,
    resizePanel,
    startDragging: (id, event) => {
      setActiveHandleId(id);
      if (isMouseEvent(event) || isTouchEvent(event)) {
        const handleElement = getResizeHandle(id);
        initialDragStateRef.current = {
          dragHandleRect: handleElement.getBoundingClientRect(),
          dragOffset: getDragOffset(event, id, direction),
          sizes: committedValuesRef.current.sizes
        };
      }
    },
    stopDragging: () => {
      resetGlobalCursorStyle();
      setActiveHandleId(null);
      initialDragStateRef.current = null;
    },
    unregisterPanel
  }), [activeHandleId, collapsePanel, direction, expandPanel, getPanelStyle, groupId, registerPanel, registerResizeHandle, resizePanel, unregisterPanel]);
  const style = {
    display: "flex",
    flexDirection: direction === "horizontal" ? "row" : "column",
    height: "100%",
    overflow: "hidden",
    width: "100%"
  };
  return createElement(PanelGroupContext.Provider, {
    children: createElement(Type, {
      children,
      className: classNameFromProps,
      "data-panel-group": "",
      "data-panel-group-direction": direction,
      "data-panel-group-id": groupId,
      style: {
        ...style,
        ...styleFromProps
      }
    }),
    value: context
  });
}
const PanelGroup = forwardRef((props, ref) => createElement(PanelGroupWithForwardedRef, {
  ...props,
  forwardedRef: ref
}));

// Workaround for Parcel scope hoisting (which renames objects/functions).
// Casting to :any is required to avoid corrupting the generated TypeScript types.
// See github.com/parcel-bundler/parcel/issues/8724
PanelGroupWithForwardedRef.displayName = "PanelGroup";
PanelGroup.displayName = "forwardRef(PanelGroup)";

function PanelResizeHandle({
  children = null,
  className: classNameFromProps = "",
  disabled = false,
  id: idFromProps = null,
  onDragging,
  style: styleFromProps = {},
  tagName: Type = "div"
}) {
  const divElementRef = useRef(null);

  // Use a ref to guard against users passing inline props
  const callbacksRef = useRef({
    onDragging
  });
  useEffect(() => {
    callbacksRef.current.onDragging = onDragging;
  });
  const panelGroupContext = useContext(PanelGroupContext);
  if (panelGroupContext === null) {
    throw Error(`PanelResizeHandle components must be rendered within a PanelGroup container`);
  }
  const {
    activeHandleId,
    direction,
    groupId,
    registerResizeHandle,
    startDragging,
    stopDragging
  } = panelGroupContext;
  const resizeHandleId = useUniqueId(idFromProps);
  const isDragging = activeHandleId === resizeHandleId;
  const [isFocused, setIsFocused] = useState(false);
  const [resizeHandler, setResizeHandler] = useState(null);
  const stopDraggingAndBlur = useCallback(() => {
    // Clicking on the drag handle shouldn't leave it focused;
    // That would cause the PanelGroup to think it was still active.
    const div = divElementRef.current;
    div.blur();
    stopDragging();
    const {
      onDragging
    } = callbacksRef.current;
    if (onDragging) {
      onDragging(false);
    }
  }, [stopDragging]);
  useEffect(() => {
    if (disabled) {
      setResizeHandler(null);
    } else {
      const resizeHandler = registerResizeHandle(resizeHandleId);
      setResizeHandler(() => resizeHandler);
    }
  }, [disabled, resizeHandleId, registerResizeHandle]);
  useEffect(() => {
    if (disabled || resizeHandler == null || !isDragging) {
      return;
    }
    const onMove = event => {
      resizeHandler(event);
    };
    const onMouseLeave = event => {
      resizeHandler(event);
    };
    const divElement = divElementRef.current;
    const targetDocument = divElement.ownerDocument;
    targetDocument.body.addEventListener("contextmenu", stopDraggingAndBlur);
    targetDocument.body.addEventListener("mousemove", onMove);
    targetDocument.body.addEventListener("touchmove", onMove);
    targetDocument.body.addEventListener("mouseleave", onMouseLeave);
    window.addEventListener("mouseup", stopDraggingAndBlur);
    window.addEventListener("touchend", stopDraggingAndBlur);
    return () => {
      targetDocument.body.removeEventListener("contextmenu", stopDraggingAndBlur);
      targetDocument.body.removeEventListener("mousemove", onMove);
      targetDocument.body.removeEventListener("touchmove", onMove);
      targetDocument.body.removeEventListener("mouseleave", onMouseLeave);
      window.removeEventListener("mouseup", stopDraggingAndBlur);
      window.removeEventListener("touchend", stopDraggingAndBlur);
    };
  }, [direction, disabled, isDragging, resizeHandler, stopDraggingAndBlur]);
  useWindowSplitterResizeHandlerBehavior({
    disabled,
    handleId: resizeHandleId,
    resizeHandler
  });
  const style = {
    cursor: getCursorStyle(direction),
    touchAction: "none",
    userSelect: "none"
  };
  return createElement(Type, {
    children,
    className: classNameFromProps,
    "data-resize-handle-active": isDragging ? "pointer" : isFocused ? "keyboard" : undefined,
    "data-panel-group-direction": direction,
    "data-panel-group-id": groupId,
    "data-panel-resize-handle-enabled": !disabled,
    "data-panel-resize-handle-id": resizeHandleId,
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
    onMouseDown: event => {
      startDragging(resizeHandleId, event.nativeEvent);
      const {
        onDragging
      } = callbacksRef.current;
      if (onDragging) {
        onDragging(true);
      }
    },
    onMouseUp: stopDraggingAndBlur,
    onTouchCancel: stopDraggingAndBlur,
    onTouchEnd: stopDraggingAndBlur,
    onTouchStart: event => {
      startDragging(resizeHandleId, event.nativeEvent);
      const {
        onDragging
      } = callbacksRef.current;
      if (onDragging) {
        onDragging(true);
      }
    },
    ref: divElementRef,
    role: "separator",
    style: {
      ...style,
      ...styleFromProps
    },
    tabIndex: 0
  });
}

// Workaround for Parcel scope hoisting (which renames objects/functions).
// Casting to :any is required to avoid corrupting the generated TypeScript types.
// See github.com/parcel-bundler/parcel/issues/8724
PanelResizeHandle.displayName = "PanelResizeHandle";

export { Panel, PanelGroup, PanelResizeHandle };
