import { IconProps } from "./IconType";

export default function LockIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="none"
      {...props}
    >
      <path d="M16.5 8.66667H15.75V7.04762C15.75 4.81333 14.07 3 12 3C10.622 3 9.41684 3.80359 8.76506 5C8.25 5.94546 8.25 7.5 8.25 8.66667H9.75C9.75 7.74713 9.75 6.45416 10 5.93188C10.3737 5.15124 11.128 4.61905 12 4.61905C13.245 4.61905 14.25 5.70381 14.25 7.04762V8.66667H9.75H8.25H7.5C6.675 8.66667 6 9.39524 6 10.2857V18.381C6 19.2714 6.675 20 7.5 20H16.5C17.325 20 18 19.2714 18 18.381V10.2857C18 9.39524 17.325 8.66667 16.5 8.66667ZM16.5 18.381H7.5V10.2857H16.5V18.381ZM12 15.9524C12.825 15.9524 13.5 15.2238 13.5 14.3333C13.5 13.4429 12.825 12.7143 12 12.7143C11.175 12.7143 10.5 13.4429 10.5 14.3333C10.5 15.2238 11.175 15.9524 12 15.9524Z" />
    </svg>
  );
}
