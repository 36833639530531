import { IconProps } from "./IconType";

export default function Login(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={0.1}
      stroke="currentColor"
      {...props}
    >
      <path d="M11.1111 7.55556L9.86664 8.8L12.1778 11.1111H3.11108V12.8889H12.1778L9.86664 15.2L11.1111 16.4444L15.5555 12L11.1111 7.55556ZM19.1111 18.2222H12V20H19.1111C20.0889 20 20.8889 19.2 20.8889 18.2222V5.77778C20.8889 4.8 20.0889 4 19.1111 4H12V5.77778H19.1111V18.2222Z" />
    </svg>
  );
}
