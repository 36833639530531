import { IconProps } from "./IconType";

export default function MolStarIcon(props: IconProps) {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="black"
      viewBox="0 0 24 24"
      strokeWidth={0}
      stroke="currentColor"
      {...props}
    >
      <path
        d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0_350_6650)">
        <path
          d="M8.66667 10.6667C7.19333 10.6667 6 11.86 6 13.3333C6 14.8067 7.19333 16 8.66667 16C10.14 16 11.3333 14.8067 11.3333 13.3333C11.3333 11.86 10.14 10.6667 8.66667 10.6667ZM8.66667 14.6667C7.93333 14.6667 7.33333 14.0667 7.33333 13.3333C7.33333 12.6 7.93333 12 8.66667 12C9.4 12 10 12.6 10 13.3333C10 14.0667 9.4 14.6667 8.66667 14.6667ZM14.0067 14C12.9067 14 12.0067 14.9 12.0067 16C12.0067 17.1 12.9067 18 14.0067 18C15.1067 18 16.0067 17.1 16.0067 16C16.0067 14.9 15.1067 14 14.0067 14ZM14.0067 16.6667C13.64 16.6667 13.34 16.3667 13.34 16C13.34 15.6333 13.64 15.3333 14.0067 15.3333C14.3733 15.3333 14.6733 15.6333 14.6733 16C14.6733 16.3667 14.3733 16.6667 14.0067 16.6667ZM15 6C12.98 6 11.3333 7.64667 11.3333 9.66667C11.3333 11.6867 12.98 13.3333 15 13.3333C17.02 13.3333 18.6667 11.6867 18.6667 9.66667C18.6667 7.64667 17.02 6 15 6ZM15 12C13.7133 12 12.6667 10.9533 12.6667 9.66667C12.6667 8.38 13.7133 7.33333 15 7.33333C16.2867 7.33333 17.3333 8.38 17.3333 9.66667C17.3333 10.9533 16.2867 12 15 12Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.25"
        />
      </g>
    </svg>
  );
}
